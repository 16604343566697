import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import { Message } from 'element-ui'
import { userInfo } from '@/api/common.js'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/views/layout'),
        children:[
            {
                path: '/',
                name: 'index',
                component: () => import('@/views/index')
            },
            {
                path: '/interspace',
                name: 'interspace',
                component: () => import('@/views/interspace'),
                meta:{ title : '空间模型'}
            },
            {
                path: '/item',
                name: 'item',
                component: () => import('@/views/item'),
                meta:{ title : '单品模型'}
            },
            {
                path: '/global',
                name: 'global',
                component: () => import('@/views/global'),
                meta:{ title : '全球案例'}
            },
            {
                path: '/panorama',
                name: 'panorama',
                component: () => import('@/views/panorama/panorama'),
                meta:{ title : '全景套餐'}
            },
            {
                path: '/productList',
                name: 'productList',
                component: () => import('@/views/productList'),
                meta:{ title : '通选套餐'}
            },
            {
                path: '/productInfo',
                name: 'productInfo',
                component: () => import('@/views/productInfo'),
            },
            {
                path: '/mall',
                name: 'mall',
                component: () => import('@/views/mall'),
                meta:{ title : '软装商城'}
            },
            {
                path: '/panorama-detail',
                name: 'panorama-detail',
                component: () => import('@/views/panorama/panorama-detail'),
            },
            {
                path: '/model-detail',
                name: 'model-detail',
                component: () => import('@/views/model-detail'),
            },
            {
                path: '/global-detail',
                name: 'global-detail',
                component: () => import('@/views/global-detail'),
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/views/register'),
            },
            {
                path: '/buyVip',
                name: 'buyVip',
                component: () => import('@/views/buyVip'),
            },
            {
                path: '/collect-detail',
                name: 'collect-detail',
                meta:{ needLogin : true},
                component: () => import('@/views/collect-detail'),
            },
            {
                path: '/img-search',
                name: 'img-search',
                component: () => import('@/views/img-search'),
            },
            {
                path: '/mall-list',
                name: 'mall-list',
                component: () => import('@/views/mall-list'),
            },
            {
                path: '/mall-detail',
                name: 'mall-detail',
                component: () => import('@/views/mall-detail'),
            },
            {
                path: '/my',
                name: 'my',
                meta:{ needLogin : true},
                component: () => import('@/views/my/my'),
                redirect: '/my/my-consult',
                children:[
                    {
                        path: 'my-consult',
                        name: 'my-consult',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-consult'),
                    },
                    {
                        path: 'my-upload',
                        name: 'my-upload',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-upload'),
                    },
                    {
                        path: 'my-quote',
                        name: 'my-quote',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-quote'),
                    },
                    {
                        path: 'my-download',
                        name: 'my-download',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-download'),
                    },
                    {
                        path: 'my-collect',
                        name: 'my-collect',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-collect'),
                    },
                    {
                        path: 'my-view-list',
                        name: 'my-view-list',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-view-list'),
                    },
                    {
                        path: 'my-view-upload',
                        name: 'my-view-upload',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-view-upload'),
                    },
                    {
                        path: 'my-vip',
                        name: 'my-vip',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-vip'),
                    },
                    {
                        path: 'my-wallet',
                        name: 'my-wallet',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-wallet'),
                    },
                    {
                        path: 'my-upload-model',
                        name: 'my-upload-model',
                        meta:{ needLogin : true},
                        component: () => import('@/views/my/my-upload-model'),
                    },
                ]
            },
        
            {
                name: '404',
                path: '/404',
                component: () => import('@/views/notFound.vue')
            },
        ]
    },
    {
        path: '/edit',
        name: 'edit',
        meta:{ needLogin : true},
        component: () => import('@/views/edit'),
    },
    {
        path: '/view-preview',
        name: 'view-preview',
        component: () => import('@/views/view-preview'),
    },
    {
        path: '/panorama-detail2',
        name: 'panorama-detail2',
        component: () => import('@/views/panorama/panorama-detail'),
    },
    {
        path: '*',    // 此处需特别注意至于最底部
        redirect: '/404'
    }
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes
})

router.beforeEach(async(to, from, next) => {
    if(to.meta.title){  // 当title有值的时候，进行赋值动作，title没值，则不进行赋值
        document.title = to.meta.title
        next()
    }else if(to.query.token){
        Cookies.set("token",to.query.token)
        delete to.query.token
        let redirect_uri = to
        let res = await userInfo()
        Object.keys(res.data).map((item)=>{
            Cookies.set(item, res.data[item], {
                expires: 1,
                path: "",
                domain: process.env.VUE_APP_BASE_DOMAIN,
            });
        })
        
        router.push(redirect_uri)

    }else if(to.meta.needLogin && !Cookies.get("user_id")){
        next({name: 'index'})
    } {
        next()
    }
})

export default router
