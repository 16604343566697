import Cookies from "js-cookie";

// 获取协议
const getAgreement = () => {
    return process.env.NODE_ENV == 'development' ? 'http://' : 'https://'
}

// 获取3级域名   从url截取    ex  xuchuguo
const get3Domain = () => {
    return 'www'
     window.location.href.split("//")[1].split(".")[0]
}

const getToken = () =>{
    return Cookies.get('token') ? Cookies.get('token') : ''
}

//  获取的  ex  .158d.com
const getDomain = () => {
    return process.env.VUE_APP_BASE_DOMAIN
}
// 获取整个url  ex     http(s)://  +  xxx.158d.com or  www.158d.com
const getDomainUrl = (isSub = true) => {
    let domain = isSub ? get3Domain() + getDomain() : 'www' + getDomain()
    return getAgreement() + domain
}


export default {
    getAgreement,
    get3Domain,
    getDomain,
    getDomainUrl,
    getToken
}