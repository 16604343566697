import request from './index.js'
import Cookies from "js-cookie";
import axios from 'axios';

// 获取页面内容
export const getPageContent = (data)=>{
    return request("/index/index/getPageContent", data)
}

export const userInfo = (() => {
    return request("/index/User/info")
})

// 获取风格列表
export const getStylelist = (data)=>{
    return request("/meiju/style/getlist", data)
}

// 获取分类列表
export const getCategoryList = (data)=>{
    return request("/meiju/category/getlist", data)
}


// 获取模型列表
// export const getCommodityList = (data)=>{
//     return request("/meiju/Commodity/getlist", data)
// }

export const getCommodityList = (data)=>{
    return request("/meiju/Commodity/list", data)
}

// 获取来源
export const getSourceList = (data)=>{
    return request("/meiju/cases/getSourceList", data)
}

export const getGloDrtaList = (data)=>{
    return request("/meiju/cases/detail", data)
}

//获取面积列表
export const getAreaLists = (data)=>{
    return request("/meiju/Vr/getAreaLists", data)
}

//获取城市列表
export const getCityLists = (data)=>{
    return request("/meiju/Vr/getCityLists", data)
}

//获取价格列表
export const getPriceLists = (data)=>{
    return request("/meiju/Vr/getPriceLists", data)
}

//图片加载失败
export const notImageLoad = (data)=>{
    return request("/meiju/Commodity/notImageLoad", data,'POST','application/json',false)
}

//获取高清图片
export const getPlusImage = (data)=>{
    return request("/meiju/Commodity/getPlusImage", data)
}

//商城分类
export const getShopCategory = (data)=>{
    return request("/meiju/Shop/Category", data)
}

//商城商品
export const getShopGoods = (data)=>{
    return request("/meiju/Shop/goods", data)
}

//商城商品详情
export const getShopGoodsDetail = (data)=>{
    return request("/meiju/Shop/goodsDetail", data)
}

export const getGoodsdetail = (data)=>{
    return request("/index/Goodsview/getdetail", data)
}

export const getFontendProductTrends = (data)=>{
    return request("/index/Brand/getFontendProductTrends", data)
}

export const getSpecifications = (data)=>{
    return request("/index/goods/getSpecifications", data)
}

//获取全景套餐列表
export const getCommonList = (data)=>{
    return request("/meiju/Vr/commonList", data)
}

//获取通选套餐列表
export const getComboList = (data)=>{
    return request("/meiju/Combo/getList", data)
}

//获取通选套餐风格列表
export const getComboStyle = (data)=>{
    return request("/meiju/Combo/getStyle", data)
}



export const getCommSelectCategory = (data)=>{
    return request("/meiju/Commodity/selectCategory", data)
}

// 获取短信验证码
export const sendsms = (data)=>{
    return request("/index/sms/sendsms", data)
}

// 短信登录
export const codeLogin = (data)=>{
    return request("/index/login/codeLogin", data)
}

// 注册
export const register = (data)=>{
    return request("/index/register/dealerRegister", data)
}

// 登录
export const login = (data)=>{
    return request("/index/login/login", data)
}

export const getGlobalList = (data)=>{
    return request("/meiju/cases/getlist", data)
}

export const detailList = (data)=>{
    return request("/meiju/Commodity/detail", data)
}


export const commodityDownload = ((data) => {
    return request("/meiju/Commodity/download", {user_id:  Cookies.get('user_id'),...data},)
})


export const wxQrcode = ((data) => {
    return request("/index/wechat/qrcode", {user_id:  Cookies.get('user_id'),...data},)
})

export const getBindState = ((data) => {
    return request("/index/wechat/getBindState", {user_id:  Cookies.get('user_id'),...data}, 'POST','application/json',false)
})

export const identificationLogin = ((data) => {
    return request("/index/login/identificationLogin", {user_id:  Cookies.get('user_id'),...data},)
})


// 获取全球案例分类
export const getCateList = (data)=>{
    return request("/meiju/cases/cateList", data)
}


// 订单号
export const orderRecharge = (data)=>{
    return request("/index/Orderrecharge/save", data)
}


export const wxpay = (data)=>{
    return request("/index/Pay/wxpay_request", data)
}

export const myBalance = (data)=>{
    return request("/meiju/user/myBalance", data)
}


// 扫码支付
export const orderinfo = (data)=>{
    return request("/index/Order/orderinfo", data)
}

// vip 信息
export const vipInfo = (data)=>{
    return request("/meiju/User/vipInfo", {user_id:  Cookies.get('user_id'),...data})
}

// 充值记录
export const rechargeRecord = (data)=>{
    return request("/meiju/User/rechargeRecord", {user_id:  Cookies.get('user_id'),...data})
}

// 子账号列表
export const childList = ()=>{
    return request("/meiju/User/childList")
}

// 删除子账号
export const delChild = (data)=>{
    return request("/meiju/User/hidden", {user_id:  Cookies.get('user_id'),...data})
}

export const getOssAccess = (() => {
    return request("/meiju/index/getOssAccess")
})

export const getVipList = (() => {
    return request("/meiju/user/vipList")
})

export const getProductList = ((data) => {
    return request("/index/Goodsview/getProductList",data)
})






/*** 大文件or批量上传公共接口 ***/
// 文件是否已经存在
export const fileIsUploaded = (data)=>{
    return request("/index/index/fileIsUploaded", data)
}
// 文件是否已经存在
export const mergeUpload = (data)=>{
    return request("/index/index/mergeUpload", data)
}
// 文件是否已经存在
export const fileChunk = (data)=>{
    return request("/index/index/fileChunk", data)
}








