import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import ViewUI from 'view-design';
// import 'view-design/dist/styles/iview.css';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/changeElementUi.css';
import 'ant-design-vue/dist/antd.css'
import  tool from  './util/tool.js'

import {Loading,MessageBox,ButtonGroup,Dropdown,DropdownMenu,DropdownItem,Button,Dialog, Pagination,Carousel,CarouselItem,Popover,
  DatePicker,Table,TableColumn,Form, FormItem,Input,Upload,Tooltip,Select,Option,Checkbox,CheckboxGroup,Progress,Icon,Cascader,
  Message,Collapse,CollapseItem,empty,image,InputNumber,radio,radioGroup 
} from 'element-ui';

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard)
import { Upload as aUpload , Icon as aIcon, Modal as aModal   } from 'ant-design-vue';
Vue.use(aUpload)
Vue.use(aIcon)
Vue.use(aModal)
Vue.use(ButtonGroup)
Vue.use(Button)
Vue.use(InputNumber)
Vue.use(radio)
Vue.use(radioGroup)
Vue.use(Loading)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dialog)
Vue.use(Pagination)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Upload)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Progress)
Vue.use(Icon)
Vue.use(image)
Vue.use(empty)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Cascader)

Vue.prototype.$tools = tool
Vue.prototype.$message = Message
Vue.prototype.$MessageBox = MessageBox

// Vue.use(ViewUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
