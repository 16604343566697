import axios from 'axios';
import Vue from 'vue'
import Cookies from "js-cookie";
import { Message } from 'element-ui'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

// 添加请求拦截器
axios.interceptors.request.use((config) => {
    if (Cookies.get('token')) {
        config.headers['Authorization'] = Cookies.get('token')
    }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

export default function request(url, data = {}, type = 'POST',headers,needTips = true) {
    return new Promise((resolve, reject) => {
        let option = {
            url,
            method: type,
            headers:{}
        }

        if(headers){
            option.headers['Content-Type'] = headers
        }

        if (type.toLowerCase() === 'get') {
            option.params = data
        } else {
            option.data = data
        }
        axios(option).then(res => {
            if (res.status == 200 && res.data && res.data.code == 1) {
                resolve(res.data)
            } else {
                if(needTips){
                     Message.error(res.data.message)
                }
                reject(res.data)
            }
        }).catch(err => {
            if(needTips){
                Message.error('网络异常')
           }
            
            reject({ msg: '网络异常' })
        })
    })
}
