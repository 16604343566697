<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
};
</script>


<style lang="less">
#app {
    height: 100vh;
}


* {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

body {
    min-width: 1440px;
    overflow: overlay;
}

a {
    text-decoration: none;
}

img {
    width: 100%;
    height: 100%;
}

div {
    box-sizing: border-box;
}
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-to-top {
    position: fixed;
    right: 50px;
    bottom: 200px;
    transition: all .3s;
    .x-icon {
        width: 2.5rem !important;
        height: 2.5rem !important;
        fill: #333 !important;
    }
}

.el-image-viewer__canvas {
    img {
        object-fit: contain;
        max-width: 80% !important;
    }
}


/* 滚动条 */
::-webkit-scrollbar-thumb:horizontal { /*水平滚动条的样式*/
    width: 4px;
    background-color: #CCCCCC;
    -webkit-border-radius: 6px;
}
::-webkit-scrollbar-track-piece {
    background-color: #fff; /*滚动条的背景颜色*/
    -webkit-border-radius: 0; /*滚动条的圆角宽度*/
}
::-webkit-scrollbar {
    width: 10px; /*滚动条的宽度*/
    height: 8px; /*滚动条的高度*/
}
::-webkit-scrollbar-thumb:vertical { /*垂直滚动条的样式*/
    height: 50px;
    background-color: #999;
    -webkit-border-radius: 4px;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 2px solid #fff;
}
::-webkit-scrollbar-thumb:hover { /*滚动条的hover样式*/
    height: 50px;
    background-color: #9f9f9f;
    -webkit-border-radius: 4px;
}
input {
    outline:none;
    border: none;
}

.clear::after {
    content: '';
    display: block;
    clear: both;
}
.img-contain {
    object-fit: contain;
}

.pophover {
    font-weight: bold;
    color: #CB0018 !important;
    font-size: 15px;
}


.pic-tip {
    .pic-tip-item {
        font-size: 16px;
        line-height: 20px;
        margin: 5px 0;
    }
}
.font-size-16 {
    font-size: 16px;
}
</style>
